.time-temp-left {
    @apply tw-text-left;
}

.time-temp-left p {
    @apply tw-text-base tw-leading-none tw-uppercase tw-font-bold tw-max-w-full tw-bg-light-gray tw-px-0 tw-py-3.5 tw-mx-0 tw-mt-[42px] tw-mb-0 tw-rounded tw-box-border;
}

.time-temp-left:first-of-type p {
    @apply tw-mt-3.5 tw-mx-0 tw-mb-0;
}

.time-temp-line {
    @apply tw-absolute tw-border tw-border-solid tw-border-light-gray tw-left-0 tw-top-1/2 tw-translate-y-[-1px];
}

.time-temp-circle {
    @apply tw-absolute tw-top-0 tw-h-10 tw-w-10 tw-text-white tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-xs tw-leading-none;
}

.time-temp-key {
    background-color: #edecea;
    padding: 42px 42px 56px;
    box-sizing: border-box;
    border-radius: 4px;
}

.time-temp-key .time-temp-line {
    @apply tw-border tw-border-solid tw-border-gray;
}

.time-temp-label {
    position: absolute;
    top: -50px;
}

.time-temp-label p {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 0;
}

.time-temp-label::before {
    content: "";
    width: 2px;
    height: 10px;
    background-color: #43413f;
    position: absolute;
    left: 50%;
    bottom: -10px;
}

.label-favorite {
    left: 45%;
    transform: translateX(-15px);
}

.label-max {
    left: 60%;
    transform: translateX(-5px);
}

.label-range {
    left: 40%;
    top: auto;
    bottom: -40px;
    width: calc(20% + 40px);
    text-align: center;
}

.label-range p {
    background-color: #edecea;
    z-index: 2;
    display: inline;
    position: relative;
    padding: 0 7px;
}

.label-range::before {
    bottom: 49%;
    left: 0;
    width: 100%;
    height: 2px;
    z-index: 1;
}

.label-temp {
    top: auto;
    bottom: -50px;
}

.label-temp::before {
    bottom: auto;
    top: -10px;
    left: 15px;
}

@media (max-width: 767px) {
    .time-temp-key {
        display: none;
    }

    .time-temp-line {
        @apply tw-hidden tw-top-auto tw-bottom-[21px];
    }

    .time-temp-circle {
        @apply tw-top-auto tw-bottom-0 tw-left-auto tw-w-[42px] tw-h-[42px] tw-border-0 before:tw-text-xs before:tw-leading-none before:tw-text-gray before:tw-w-full before:tw-text-center before:tw-absolute before:tw-top-[-21px] before:tw-left-0;
    }
}